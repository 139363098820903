$(document).ready(function () {

  $(".categoryBlock").each(function (index, value) {

    var itemsTotal = $(this).find(".categoryBlock__slider--item").length;
    var slider = $(this).find(".categorySlider");

    slider.slick({
      slidesToShow: 3,
      swipe: false,
      swipeToSlide: false,
      infinite: false,
      prevArrow:
        `<button type="button" class="slick-prev"><img src="${$('#arrow-slick-prev').data('url')}" alt=""></button>`,
      nextArrow:
        `<button type="button" class="slick-next"><img src="${$('#arrow-slick-next').data('url')}" alt=""></button>`,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            swipe: true,
            swipeToSlide: true,
            arrows: false,
            infinite: false
          }
        },
        {
          breakpoint: 768,
          settings: {
            arrows: false,
            centerPadding: "0px",
            centerMode: true,
            swipe: true,
            swipeToSlide: true,
            slidesToShow: 1
          }
        }
      ]
    });

  });

});
